@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"),
    url("./fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Gothampro";
  font-style: normal;
  font-weight: normal;
  src: local("Gothampro"),
    url("./fonts/gothampronarrow_medium.ttf") format("truetype");
}

@font-face {
  font-family: "VisueltPro";
  font-style: normal;
  font-weight: normal;
  src: local("VisueltPro"),
    url("./fonts/VisueltPro-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "TTNormsPro";
  font-style: normal;
  font-weight: normal;
  src: local("TTNormsPro"),
    url("./fonts/TTNormsPro-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat"),
    url("./fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "calibri";
  font-style: normal;
  font-weight: normal;
  src: local("calibri"),
    url("./fonts/calibri.ttf") format("truetype");
}


@font-face {
  font-family: "Micra";
  font-style: normal;
  font-weight: normal;
  src: local("Micra"),
    url("./fonts/micra.ttf") format("truetype");
}

@font-face {
  font-family: "Micra-bold";
  font-style: bold;
  font-weight: bold;
  src: local("Micra-bold"),
    url("./fonts/micra-bold.ttf") format("truetype");
}

@font-face {
  font-family: "Suissnord";
  // font-style: normal;
  // font-weight: normal;
  src: local("Suissnord"),
    url("./fonts/Suissnord.otf") format("opentype");
}

@font-face {
  font-family: "calibri_bold";
  // font-style: normal;
  // font-weight: normal;
  src: local("calibri_bold"),
    url("./fonts/calibri_bold.ttf") format("truetype");
}

@font-face {
    font-family: "Inter";
    // font-style: normal;
    // font-weight: normal;
    src: local("Inter"), url("./fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
  }

body {
  font-family: 'calibri';
  color: white;
  background-color: gray;
  overflow: hidden;
}

p {
  margin: 0px;
}
