@import "design-tokens/css-variables.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        margin: auto;
        background: $color-background-box;
        border: 1px solid $color-background-box-border;
        border-radius: 15px;
        box-sizing: border-box;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        padding: 40px;

        .title {
            color: $color-text-header-block;
            text-align: center;
            text-transform: uppercase;
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 50px;
        }

        .backLink {
            color: #1ceef0;
        }
    }
}

.fullScreen {
    position: absolute;
    top: 0px;
    left: 0px;
    margin: auto;
    width: 100%;
    height: 100%;
    background: url('/images/errorLayer.jpg');
    background-size: 1600px;
    z-index: 1;
}
