.back {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	background: url(layer.jpg);
	background-size: 1600px;

	.loaders {
		display: flex;
		margin-right: 200px;
	}
	
	.loaderss {
		display: flex;
		margin-top: -95px;
		margin-left: 104px;
	}
}
